import React, {createContext, useContext, useMemo, useRef} from 'react';

export const scrollContext = createContext();

export function ScrollProvider(props) {
    const nieuwsRef = useRef();
    const gallerijRef = useRef();
    const lessenRef = useRef();
    const groepenRef = useRef();
    const contactRef = useRef();

    const api = useMemo(() => ({
        nieuwsRef,
        gallerijRef,
        lessenRef,
        groepenRef,
        contactRef
    }), [nieuwsRef,gallerijRef,lessenRef,groepenRef,contactRef]);

    return <scrollContext.Provider value={api}>
        {props.children}
    </scrollContext.Provider>
}

export const useScrollContext = () => useContext(scrollContext);