import { Button, Col, Container, FloatingLabel, Form, Row, Alert } from "react-bootstrap";
import emailjs from 'emailjs-com';
import { useLoadingContext } from "../context/LoadingContext";
import ScaleLoader from "react-spinners/ClipLoader";
import { useState } from "react";

export function Contact() {
    const { loading } = useLoadingContext();
    const { setLoading } = useLoadingContext();
    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);


    function sendMail(e) {
        setLoading(true);
        e.preventDefault();
        setShowAlert(false);
        setShowError(false);

        emailjs.sendForm('service_ydu5f88', 'template_jh68mhj', e.target, 'user_PPpSpfPeJ2bq8l4t9Al08')
            .then((result) => {
                console.log(result.text);
                setLoading(false);
                setShowAlert(true);
            }, (error) => {
                console.log(error.text);
                setLoading(false);
                setShowError(true);
            });


    }

    return <Container id="contact" fluid className="bgPurple my-5 me-0 px-5 py-5 skewedRightTop">
        <div className="skewedRightContent">
            {loading &&

                <Container fluid className="d-flex justify-content-center align-items-center loader p-1">
                    <ScaleLoader color={"#36D7B7"} loading={loading} size={25} />
                </Container>

            }
            <h2 className="font-type hoofding text-center m-2 text-light">Contact</h2>
            <div className="my-5">
                <h5 className="font-type text-center m-2 text-light">
                    Zin om ook eens mee te dansen?
                </h5>
                <h5 className="font-type text-center m-2 text-light">
                    Zit je met een vraag die je graag beantwoord ziet?
                </h5>
                <h5 className="font-type text-center m-2 text-light">
                    Contacteer ons dan via onderstaand formulier.
                </h5>
            </div>
            <Row className="my-3 bgWhite shadow justify-content-center rounded shadow-lg  px-4">
                <Col className="my-5" lg={6} md={12}>
                    <Form onSubmit={sendMail}>
                        <Row>
                            <Col md>
                                <p>Alle velden gemarkeerd met (*) zijn vereist</p>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGridNaam" label="Naam (*)">
                                    <Form.Control required name="from_lastName" type="text" placeholder="Naam" />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGridVoorNaam" label="Voornaam (*)">
                                    <Form.Control required name="from_firstName" type="text" placeholder="Voornaam" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg>
                                <FloatingLabel controlId="floatingInputGridEmail" label="Email (*)">
                                    <Form.Control required name="reply_to" type="email" placeholder="Email" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGridOnderwep" label="Onderwerp">
                                    <Form.Control name="subject" type="text" placeholder="Onderwerp" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg>
                                <FloatingLabel controlId="floatingInputGridBericht" label="Bericht">
                                    <Form.Control
                                        name="message"
                                        as="textarea"
                                        placeholder="Uw bericht hier"
                                        style={{ height: '10rem' }} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Button type={"submit"} style={{
                                width: "50%",
                                backgroundColor: "rgb(57, 10, 79)",
                                opacity: "0.9",
                                border: "none"
                            }}>
                                Indienen
                            </Button>
                        </Row>
                        <Row className="my-2">
                            <Col className="d-flex justify-content-center align-items-center" sm={12}>
                                <Alert variant={'success'} show={showAlert}>
                                    <h5>Bedankt, jouw bericht is ontvangen</h5>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col className="d-flex justify-content-center align-items-center" sm={12}>
                                <Alert variant={'danger'} show={showError}>
                                    <h5>Er ging iets mis, gelieve telefonisch contact op te nemen</h5>
                                </Alert>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col className="my-5" lg={6} md={12}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.6090954505908!2d4.482831551310758!3d51.0603053510144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3e504f35e1095%3A0x390b9be95cd14c89!2sGemeentelijke%20basisschool%20Octopus!5e0!3m2!1snl!2sbe!4v1634635784684!5m2!1snl!2sbe"
                        style={{ border: "0", width: "100%", height: "100%" }} title="googlemap" allowFullScreen="" loading="lazy"></iframe>
                </Col>
            </Row>
        </div>
    </Container>;
}