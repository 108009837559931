import {Container, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Tabs, Tab} from "react-bootstrap";
import {getCollection} from "../services/firestoreGetCollection";

export function ClassSchedule({danceGroup}) {

    return <Container className="text-light ml-5 text-center">
        {danceGroup.rooster.length > 0 ? danceGroup.rooster.map((entry) => <p>{entry.dag + ": " + entry.uren}</p>) :
            <p>Geen lessen</p>}
    </Container>
}

export function ClassInfo({classesArray}) {
    const [key, setKey] = useState('Rooster')
    const [classes, setClasses] = useState([])
    const [lessen, setLessen] = useState({});
    useEffect(() => {
        async function getGroepen() {
            const data = await getCollection("classCategories");
            console.log(data)
            return setClasses(data);
        }

        getGroepen();
    }, [])
    useEffect(() => {
        async function getLessen() {
            const data = await getCollection("classes");
            console.log(data);
            return setLessen(data);
        }

        getLessen()
    }, [])
    console.log(classes)
    return <Container id="lessen" fluid className="bgPurple m-0 me-0 px-5 py-5 skewedLeftTop">
        <div className="skewedLeftTopContent">
            <h2 className="font-type text-light text-center  hoofding">Lessen 2024 - 2025</h2>
            <Tabs
                id="controlled-tab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 d-flex justify-content-center mt-3 "
            >
                <Tab eventKey="Rooster" title="Rooster">
                    <Table size={"sm"} hover striped responsive bordered className="w-100 border mt-3 rounded bg-light">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Maandag</th>
                            <th>Dinsdag</th>
                            <th>Woensdag</th>
                            <th>Donderdag</th>
                            <th>Vrijdag</th>
                            <th>Zaterdag</th>
                        </tr>
                        </thead>
                        <tbody>
                        {lessen.length > 0 && lessen[0].groepen.map(les => {
                            return <tr>
                                <td>{les.groepNaam}</td>
                                {les.uren.map(timeslot => {
                                    return Object.values(timeslot).map(uur => {
                                        return <td>{uur}</td>
                                    })
                                })
                                }
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </Tab>
                {/*classes.length > 0 && classes[0].categories.map(category => {
                    return <Tab eventKey={category} title={category}>
                        <Table size={"sm"}  variant="dark" responsive hover striped  bordered className="w-100 border rounded  mt-3">
                            <thead>
                            <th></th>
                            <th>Leeftijd</th>
                            <th>Maandag</th>
                            <th>Dinsdag</th>
                            <th>Woensdag</th>
                            <th>Donderdag</th>
                            <th>Vrijdag</th>
                            <th>Zaterdag</th>
                            </thead>
                            <tbody>
                            {lessen.length > 0 && lessen[0].groepen.map(les => {
                                if (les.category === category){
                                    return <tr>
                                        <td>{les.groepNaam}</td>
                                        <td>{les.leeftijd}</td>
                                        {les.uren.map(timeslot => {
                                            return Object.values(timeslot).map(uur => {
                                                return <td>{uur}</td>
                                            })
                                        })}
                                    </tr>
                                }
                            })}
                            </tbody>
                        </Table>
                    </Tab>
                })*/}
            </Tabs>
        </div>

    </Container>;
}

function GenerateTable() {

}



