import firebase from "firebase/compat/app";
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";
import 'firebase/firestore';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const database = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

if (database)
    console.log(`connection to database is ok`);
else
    console.log(`ERROR: no connection to database`);
if (storage) {
    console.log("connection to storage ok")
} else {
    console.log("ERROR: no connection to storage");
}