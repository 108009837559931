import logo from './components/images/hd_transparent_logo.png';
import * as React from "react";
import './App.css';
import "./services/firestore"
import { Footer } from "./components/Footer";
import { ARTICLES_DATA, CLASS_DATA, GALLERY_DATA } from "./data/data";
import { Articles } from "./components/Article";
import { ClassInfo } from "./components/ClasInfo";
import { Gallery } from "./components/Gallery";
import { Contact } from "./components/Contact";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from "./components/Header";
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { SingUp } from "./components/Sign-ups";
import { LoadingProvider } from './context/LoadingContext';
import { ScrollProvider } from './context/scrollContext';
import { Container, Navbar, Nav } from "react-bootstrap";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from "react-router-dom";
import { faFacebook, faTiktok, faInstagram } from "@fortawesome/free-brands-svg-icons";
import * as Scroll from 'react-scroll';
import { useRef } from 'react';
import { SpaghettiForm } from './components/SpaghettiForm';
import { DanceMarathon } from './components/DanceMarathon';



function App() {
    const nieuwsRef = useRef();
    const groepenRef = useRef();
    const inschrijvenRef = useRef();
    const contactRef = useRef();


    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <LoadingProvider>
                        <ScrollProvider>
                            <Navbar className='skewedLeft bgPurple' expand="lg">
                                <Container fluid>
                                    <Navbar.Brand className="m-2" >
                                        <img
                                            src={logo}
                                            style={{ height: "5rem", width: "10rem" }}
                                            className="d-inline-block align-top mb-2"
                                            alt=""
                                        />
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between text-center">
                                        <Nav.Link onClick={() => nieuwsRef.current.scrollIntoView()} className="text-light font-type">Nieuws</Nav.Link>
                                        <Nav.Link onClick={() => inschrijvenRef.current.scrollIntoView()} className="text-light font-type">Inschrijven</Nav.Link>
                                        <Nav.Link onClick={() => groepenRef.current.scrollIntoView()} className="text-light font-type">Lessen</Nav.Link>
                                        <Nav.Link onClick={() => groepenRef.current.scrollIntoView()} className="text-light font-type">Groepen</Nav.Link>
                                        <Nav.Link onClick={() => contactRef.current.scrollIntoView()} className="text-light font-type">Contact</Nav.Link>
                                        <div className="align-items-center text-center">
                                            <a href="https://www.facebook.com/danceatbeat.danceatbeat">
                                                <FontAwesomeIcon icon={faFacebook}
                                                    style={{
                                                        color: "white",
                                                        opacity: "0.9",
                                                        border: "none",
                                                        height: "5%",
                                                        width: "5%"
                                                    }} />
                                            </a>
                                            <a href="https://www.instagram.com/dance_at_beat/">
                                                <FontAwesomeIcon icon={faInstagram}
                                                    className="mx-3"
                                                    style={{
                                                        color: "white",
                                                        opacity: "0.9",
                                                        border: "none",
                                                        height: "5%",
                                                        width: "5%"
                                                    }} />
                                            </a>
                                            <a href="https://www.tiktok.com/@danceatbeatdansschool">
                                                <FontAwesomeIcon icon={faTiktok} style={{
                                                    color: "white",
                                                    opacity: "0.9",
                                                    border: "none",
                                                    height: "5%",
                                                    width: "5%"
                                                }} />
                                            </a>
                                        </div>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                            <Header />
                            <div ref={nieuwsRef}></div>
                            <Articles  />
                            <div ref={groepenRef}></div>
                            {/* <SpaghettiForm />  */}
                            <ClassInfo  classesArray={CLASS_DATA} />
                            <div ref={inschrijvenRef}></div>
                            <SingUp />
                            {/* <DanceMarathon /> */}
                            <div ref={contactRef}></div>
                            <Contact  />
                            <Footer />
                        </ScrollProvider>
                    </LoadingProvider>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
