import {Card, Container, Modal, ModalBody, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useEffect, useState} from "react";
import {getCollection} from "../services/firestoreGetCollection";
import {Button} from "react-bootstrap";

export function Articles() {
    const [firstThree, setFirstThree] = useState([]);
    useEffect(() => {
        async function loadArticles() {
            const articles = await getCollection("articles")
            setFirstThree(articles.sort(function(x, y){
                return y.date - x.date
            }).slice(0, 3))
        }

        loadArticles()
    }, [])
    return <Container id="nieuws" fluid className="bgWhite  skewedBoth ">
        <div className="skewedBothContent p-5">
            <h2 className="font-type text-center hoofding p-2 mb-5">Nieuws</h2>
            {firstThree.length > 0 && firstThree.map((a) => firstThree.indexOf(a) % 2 ?
                <Article key={a.albumName} article={a} even={true}/> :
                <Article key={a.albumName} article={a} even={false}/>)}
        </div>
    </Container>;
}

export function Article({article, even}) {
    const [articleShow,setArticleShow] = useState(false)
    const dimensies = useWindowDimensions()

    function setPositioning() {
        if (dimensies.width <= 800) {
            return "justify-content-center"
        }
        if (even) {
            return "justify-content-end"
        } else {
            return "justify-content-start"
        }
    }

    return (
        <Row
            className={setPositioning()}>
            <Card
                className={dimensies.width <= 800 ? "mb-5 mx-3 d-flex w-100 shadow p-3" : "mb-5 mx-3 d-flex w-75 shadow p-3"}>
                <Card.Title>{article.title}</Card.Title>
                <Card.Body>
                    <blockquote className="mb-0">
                        <p>
                            {article.content && article.content.substr(0, 100)}...
                        </p>
                        <footer className="blockquote-footer mt-3">
                            Geschreven op <cite className="font-type font-color mt-5" title="Datum">
                            {article.date && `${article.date.toDate().toDateString()}`}
                        </cite>
                        </footer>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button onClick={() => setArticleShow(true)} className="btn-warning">
                                "Meer lezen -{">"}"
                            </Button>
                        </div>
                    </blockquote>
                </Card.Body>
            </Card>
            <Modal show={articleShow}>
                <Modal.Header closeButton onClick={() => setArticleShow(false)}>{article.title}</Modal.Header>
                <Modal.Body>
                    {article.content}
                </Modal.Body>
            </Modal>
        </Row>)
}

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width, height
    }
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}