import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faTiktok, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {Col, Container, Row} from "react-bootstrap";

export function Footer() {
    return <Container fluid className="my-5">
        <Row className="d-flex my-5">
            <Col sm={12} className="d-flex justify-content-center align-items-center">
                <a href="https://www.facebook.com/danceatbeat.danceatbeat">
                    <FontAwesomeIcon icon={faFacebook} style={{
                        color: "rgb(57, 10, 79)",
                        opacity: "0.9",
                        border: "none",
                        height: "3.2rem",
                        width: "3.2rem"
                    }}/>
                </a>
                <a href="https://www.tiktok.com/@danceatbeatdansschool">
                    <FontAwesomeIcon icon={faTiktok} style={{
                        color: "rgb(57, 10, 79)",
                        opacity: "0.9",
                        border: "none",
                        height: "3.2rem",
                        width: "3.2rem"
                    }}/>
                </a>

                <a href="https://www.instagram.com/dance_at_beat/">
                    <FontAwesomeIcon icon={faInstagram} className=""
                                     style={{
                                         color: "rgb(57, 10, 79)",
                                         opacity: "0.9",
                                         border: "none",
                                         height: "3.2rem",
                                         width: "3.2rem"
                                     }}/>
                </a>
            </Col>
        </Row>
        <Row className="align-items-end justify-content-end mt-3">
            <p className="footerText" style={{color: "rgb(57, 10, 79)"}}>Made by Lander Marien</p>
        </Row>
    </Container>
        ;
}