import "firebase/firestore";
import {database} from "./firestore";
import {collection, query, getDocs } from "firebase/firestore";

export async function getCollection(name) {
    if (!database) return [];
    const q = query(collection(database,name))
    const querySnapshot = await getDocs(q);
    const result = [];
    if (result.empty) return result;
    else {
        querySnapshot.docs.forEach(doc => {
            result.push(doc.data());
        })
    }
    return result;
}