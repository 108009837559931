import React, {createContext, useContext, useMemo, useState} from 'react';

export const LoadingContext = createContext();

export function LoadingProvider(props) {
    const [loading, setLoading] = useState(false);

    const api = useMemo(() => ({
        loading,setLoading
    }), [loading,setLoading]);

    return <LoadingContext.Provider value={api}>
        {props.children}
    </LoadingContext.Provider>
}

export const useLoadingContext = () => useContext(LoadingContext);