import { Button, Col, Container, FloatingLabel, Form, Row, Alert } from "react-bootstrap";
import emailjs from 'emailjs-com';
import Select from 'react-select';
import { getCollection } from "../services/firestoreGetCollection";
import { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ClipLoader";
import { useLoadingContext } from "../context/LoadingContext";



import { React } from 'react';

export function SingUp() {
    const [lessen, setLessen] = useState({});
    const [groupSelection, setGroupSelection] = useState([]);

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [rrNumber, setRrNumber] = useState("");
    const [street, setStreet] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [busNumber, setBusNumber] = useState("");
    const [place, setPlace] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [birthday, setBirthDay] = useState("");
    const [chosenGroups, setChosenGroups] = useState([]);
    const [parentPhone, setParentPhone] = useState("");
    const [parentEmail, setParentEmail] = useState("");
    const [phoneMember, setPhoneMember] = useState("");
    const [emailMember, setEmailMember] = useState("");
    const { loading } = useLoadingContext();
    const { setLoading } = useLoadingContext();
    const [selectedOption,setSelectedOption] = useState("1");

    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);
    const [calculated, setCalculated] = useState(false);

    const totalCost = 0;

    const [color, setColor] = useState("#ffffff");
    useEffect(() => {
        async function getLessen() {
            const data = await getCollection("classes");
            console.log(data);
            return setLessen(data);
        }

        getLessen()


    }, [])

    useEffect(() => {
        createOptions()
    }, [lessen])


    useEffect(() => {

    }, [calculated])


    function createOptions() {
        let options = []
        for (const [key, value] of Object.entries(lessen)) {
            for (const groep of value.groepen) {
                options.push({ name: groep.groepNaam, leeftijd: groep.leeftijd })
            }

        }
        let selectionOptions = options.filter(groep => groep.name !== "Fitness");
        selectionOptions.push({ name: "Fitness Dinsdag", leeftijd: "Vanaf 16 jaar" }, { name: "Fitness Woensdag", leeftijd: "Vanaf 16 jaar" }, { name: "Fitness Donderdag", leeftijd: "Vanaf 16 jaar" });
        let formattedSelections = [];

        for (const group of selectionOptions) {
            formattedSelections.push({ value: group.name, label: group.name + " | " + group.leeftijd });
        }

        setGroupSelection(formattedSelections);

    };

    function calculateCost() {
        console.log(selectedOption)
        let placeHolderCost = 0;
        let placeHolderGroups = chosenGroups;
        let allFitness = false;
        let onlyOne = false
        setLoading(true);
        setShowAlert(false);
        setShowError(false);

        if (placeHolderGroups.includes("Fitness Dinsdag") && placeHolderGroups.includes("Fitness Woensdag") && placeHolderGroups.includes("Fitness Donderdag")) {
            placeHolderCost = 235;
            placeHolderGroups = placeHolderGroups.filter(word => !word.includes("Fitness"))
            console.log(placeHolderGroups);
            allFitness = true;
        }

        if (placeHolderGroups.length === 1 && allFitness === false) {
            placeHolderCost = 150;
            onlyOne = true
        } else {
            if (onlyOne === false && allFitness === false) {
                placeHolderCost = 150;
                placeHolderCost += 85 * (placeHolderGroups.length - 1);
            } else {
                if (placeHolderGroups.length >= 1 && allFitness === false) {
                    placeHolderCost = 150;
                    placeHolderCost += 85 * (placeHolderGroups.length - 1);
                } else {
                    placeHolderCost += 85 * (placeHolderGroups.length);
                }
            }
        }
        if(selectedOption === "2"){
            placeHolderCost = placeHolderCost - 10
        }
        if(selectedOption === "3"){
            placeHolderCost = placeHolderCost - 30
        }
        console.log(placeHolderCost);

        submitEmail(placeHolderCost);
    }


    function submitEmail(totalCost) {
        setLoading(true);
        let aplliedDiscount = 0;
        let Korting = "Geen"
        if(selectedOption === "2"){
            Korting = "10 €"
            aplliedDiscount = 10;
        }
        if(selectedOption === "3"){
            Korting = "30 €"
            aplliedDiscount = 30
        }
        
        const formInformation = {
            name: name,
            surname: surname,
            rrNumber: rrNumber,
            street: street,
            houseNumber: houseNumber,
            busNumber: busNumber,
            postalCode: postalCode,
            place: place,
            birthday: birthday,
            parentPhone: parentPhone,
            parentEmail: parentEmail,
            phoneMember: phoneMember,
            emailMember: emailMember,
            chosenGroups: chosenGroups,
            korting : Korting,
            totalCost: totalCost,
            insurancePremium: 15,
            preDiscount : aplliedDiscount + totalCost
        }

        emailjs.send('service_1j5khlc', 'template_nfeyc74', formInformation, 'user_PPpSpfPeJ2bq8l4t9Al08')
            .then((result) => {
                console.log(result.text);
                setLoading(false);
                setShowAlert(true);
                clearForm();
            }, (error) => {
                console.log(error.text);
                setLoading(false);
                setShowError(true);
            })

    }


    function sendMail(e) {
        e.preventDefault();
        calculateCost()
    }

    function clearForm() {
        document.getElementById("subscribeform").reset();
    }

    function handleChange(option) {
        let placeholderArray = []
        for (const group of option) {
            placeholderArray.push(group.value);
        }
        console.log(placeholderArray);
        setChosenGroups(placeholderArray);
        return;
    }

    const handleChangeRadio = e => {
        const target = e.target;
        if (target.checked) {
          setSelectedOption(e.target.value)
        }
        
     };

     




    return <Container id="contact" fluid className="bgWhite my-5 me-0 px-5 py-5 skewedLeftTopContent">
        <div className="skewedBothContent">

            <h2 className="font-type hoofding text-center m-2 text-dark">Inschrijvingen 2024-2025</h2>
            <div className="my-5 mx-5 font-type text-dark">
                <article>
                    <h5>
                        Dansers
                    </h5>
                    <p>
                        Het inschrijvingsgeld bedraagt voor een heel jaar 150 euro. Betalen per semester kan ook, per semester betaal je dan 85 euro. Als je in meerdere groepen danst betaal je per groep 85 euro extra.
                    </p>
                </article>
                <article>
                    <h5>
                        Fitness
                    </h5>
                    <p>
                        Het inschrijvingsgeld bedraagt 150 euro voor 1 lesuur. Voor het bedrag van 235 euro kan je alle lessen (3) volgen.
                    </p>
                </article>
                <strong>
                    Bij betaling van het lidgeld voor 19 september 2024 is de verzekering inbegrepen bij het lidgeld.
                </strong>
                <br></br>
                <strong>
                    Bij betalingen van het lidgeld na 19 september zal een extra bedrag worden aangerekend van 15 euro per lid voor de verzekering.
                </strong>
                <article className="my-2">
                    <p>
                        Na het invullen van ondertsaand formulier zal u een e-mail ontvangen waar verdere info in zal vermeld staan om de inschrijving te voltooien.
                    </p>
                </article>
            </div>
            <Row className="my-3 bgWhite shadow justify-content-center rounded shadow-lg  px-4">
                <Col className="my-5" lg={6} md={12}>
                    <Form id="subscribeform" onSubmit={sendMail}>
                        <Row>
                            <Col md>
                                <p>Alle velden gemarkeerd met (*) zijn vereist</p>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGridNaam" label="Naam (*)">
                                    <Form.Control required name="from_lastName" type="text" placeholder="Naam" onChange={(e) => setName(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGridVoorNaam" label="Voornaam (*)">
                                    <Form.Control required name="from_firstName" type="text" placeholder="Voornaam" onChange={(e) => setSurname(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Container fluid className="border my-3 ">
                            <h5 className="my-4 font-color">
                                Informatie vereist voor de verzekering
                            </h5>
                            <Row className="my-2">
                                <Col lg>
                                    <FloatingLabel controlId="floatingInputGridRRNumber" label="Rijksregisternummer (*)">
                                        <Form.Control required name="rijksregisternummer" type="text" placeholder="Rijksregisternummer" onChange={(e) => setRrNumber(e.target.value)} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col lg>
                                    <FloatingLabel controlId="floatingInputGridStreet" label="Straatnaam (*)">
                                        <Form.Control required name="straatnaam" type="text" placeholder="Straatnaam" onChange={(e) => setStreet(e.target.value)} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col md>
                                    <FloatingLabel controlId="floatingInputGridStreetNumber" label="Huisnummer (*)">
                                        <Form.Control required name="huisnummer" type="text" placeholder="Huisnummer" onChange={(e) => setHouseNumber(e.target.value)} />
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel controlId="floatingInputGridStreetBusNumber" label="Busnummer">
                                        <Form.Control name="busnummer" type="text" placeholder="Busnummer" onChange={(e) => setBusNumber(e.target.value)} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col lg>
                                    <FloatingLabel controlId="floatingInputGridStreetNumber" label="Postcode (*)">
                                        <Form.Control required name="postalCode" type="text" placeholder="Postcode" onChange={(e) => setPostalCode(e.target.value)} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col lg>
                                    <FloatingLabel controlId="floatingInputGridStreetNumber" label="Plaats (*)">
                                        <Form.Control required name="plaats" type="text" placeholder="Plaats" onChange={(e) => setPlace(e.target.value)} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="my-2">
                                <Col lg>
                                    <FloatingLabel controlId="floatingInputGridStreetBusNumber" label="Geboortedatum (*)">
                                        <Form.Control required name="geboortedatum" type="date" placeholder="Busnummer" onChange={(e) => setBirthDay(e.target.value)} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Container>

                        <Row className="my-2">
                            <Col>
                                <label className="mb-2">Gewenste groepen</label>
                                <Select isMulti className="basic-multi-select"
                                    classNamePrefix="Gewenste groepen" options={groupSelection} onChange={(option) => handleChange(option)} />
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <p>Voor welk gezinslid is deze inschrijving? (Korting vanaf het tweede)</p>
                            <Col className="d-flex justify-content-between my-2" md>
                                
                                    <label>
                                        <input
                                            type="radio"
                                            name="react-tips"
                                            value="1"
                                            checked={selectedOption === "1"}
                                            onChange = {handleChangeRadio}
                                            className="form-check-input"
                                        />
                                        Eerste lid
                                    </label>
                                

                                
                                    <label>
                                        
                                        <input
                                            type="radio"
                                            name="react-tips"
                                            value="2"
                                            checked={selectedOption === "2"}
                                            onChange = {handleChangeRadio}
                                            className="form-check-input"
                                        />
                                        Tweede lid
                                    </label>
                                

                                
                                    <label>
                                        <input
                                            type="radio"
                                            name="react-tips"
                                            value="3"
                                            checked={selectedOption === "3"}
                                            onChange = {handleChangeRadio}
                                            className="form-check-input"
                                        />
                                        Derde lid
                                    </label>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel controlId="floatingInputGridEmail" label="Telefoonnummer Ouder (*)">
                                    <Form.Control required name="primaryContact" type="text" placeholder="Email" onChange={(e) => setParentPhone(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg>
                                <FloatingLabel required controlId="floatingInputGridEmail" label="Email Ouder (*)">
                                    <Form.Control name="emailOuder" type="email" placeholder="Email" onChange={(e) => setParentEmail(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGridOnderwep" label="Telefoonnummer lid">
                                    <Form.Control name="phoneNumberMember" type="text" placeholder="Onderwerp" onChange={(e) => setPhoneMember(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col lg>
                                <FloatingLabel controlId="floatingInputGridEmail" label="Email lid">
                                    <Form.Control name="emailMember" type="email" placeholder="Email" onChange={(e) => setEmailMember(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {loading &&

                            <Container fluid className="d-flex justify-content-center align-items-center loader p-1">
                                <ScaleLoader color={"#36D7B7"} loading={loading} size={25} />
                            </Container>

                        }
                        <Row className="justify-content-center mt-4">
                            <Button type={"submit"} style={{
                                width: "50%",
                                backgroundColor: "rgb(57, 10, 79)",
                                opacity: "0.9",
                                border: "none"
                            }}>
                                Inschrijven
                            </Button>
                        </Row>
                        <Row className="my-3">
                            <Col className="d-flex justify-content-center align-items-center" sm={12}>
                                <Alert variant={'success'} show={showAlert}>
                                    <h5>Bedankt, jouw inschrijving is ontvangen. Je ontvangt verdere instructies via mail.</h5>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col className="d-flex justify-content-center align-items-center" sm={12}>
                                <Alert variant={'danger'} show={showError}>
                                    <h5>Er ging iets mis met de inschrijving, gelieve telefonisch contact op te nemen</h5>
                                </Alert>
                            </Col>
                        </Row>
                    </Form>
                </Col>

            </Row>
        </div>
    </Container>;
}