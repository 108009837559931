

/* ------------------ DANCE_CLASSES ----------------------*/

export const CLASS_DATA = [{
    name : 'Groep 1',
    rooster : [
        {dag : "Maandag",
        uren : "18u - 19u"}

    ]
},{
    name : 'Groep 2',
    rooster : [
        {dag : "Maandag",
        uren : "20u - 21u"},{
        dag: "Woensdag",
        uren : "18u - 19u"
        }

    ]
},{
    name : 'Groep 3',
    rooster : [
        {dag : "Dinsdag",
        uren : "19u - 20u"},
        {dag : "Woensdag",
        uren : "20u - 21u"}
    ]
},{
    name : 'Groep 4',
    rooster : [
        {dag : "Dinsdag",
            uren : "21u - 22u"},
        {dag : "Woensdag",
            uren : "19u - 20u"}
    ]
},{
    name : 'Groep Zero',
    rooster : []
}]
